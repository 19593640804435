import {
  Grid,
  ListItemText,
  OutlinedInput,
  Pagination,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import TitleDiv from "components/Common/TitleDiv";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  BodyTableCell,
  FormButton,
  FormDropDown,
  FormInput,
  FormSelectCheckBox,
  FormSelectMenuItem,
  TitleTableCell,
} from "style/theme";
import CRMApi from "system/api/CRMApi";
import UserApi from "system/api/UserApi";
import { ErrorHandler } from "system/ApiService";
import { useLoadingDispatch } from "system/context/LoadingContext";
import { useUserState } from "system/context/UserContext";
import {
  HandlingMethod,
  PaymentType,
  ResultForVisit,
  SearchDateType,
  SearchDateType2,
  SearchItemType,
  SearchStatusType,
} from "system/CRMConstants";
import { Branch, CRM, GetInitSearchResult, SearchCRM, SearchResult, TreeCategory } from "system/types";
import useSearchStore from "system/useSearchStore";
import SearchTable from "./SearchTable";

function SearchMain() {
  const Loading = useLoadingDispatch();
  const user = useUserState();
  const { searchForm, setSearchForm } = useSearchStore();
  const [crm, setCRM] = useState<SearchResult<CRM>>(GetInitSearchResult([]));
  const [itemCategory, setItemCategory] = useState<TreeCategory[]>([]);
  const [branch, setBranch] = useState<Branch[]>([]);
  const [ngCategory, setNGCategory] = useState<TreeCategory[]>([]);
  const [resultCategory, setResultCategory] = useState<TreeCategory[]>([]);

  useEffect(() => {
    const fetchBaseData = async () => {
      try {
        const [categoryRes, branchRes, ngCategoryRes, resultCategoryRes] = await Promise.all([
          CRMApi.GetItemCategory(false),
          UserApi.GetBranchList(),
          CRMApi.GetNGCategory(),
          CRMApi.GetResultCategory(),
        ]);
        setItemCategory(categoryRes);
        setBranch([{ branchId: "", branchName: "선택" }, ...branchRes]);
        setNGCategory(ngCategoryRes);
        setResultCategory(resultCategoryRes);

        if (user.authority === "BranchUser") {
          setSearchForm({ branch: user.branch });
        }
      } catch (err: any) {
        let msg = ErrorHandler(err);
        console.log(msg);
      }
    };
    fetchBaseData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.authority, user.branch]);

  const onSearch = () => {
    Loading({ type: "LOADING" });

    const searchParams = {
      ...searchForm,
      category: Array.isArray(searchForm.category) ? searchForm.category.join(",") : searchForm.category,
      branch: user.authority === "BranchUser" ? user.branch : searchForm.branch,
    };

    CRMApi.GetCRMList(searchParams)
      .then((res) => {
        setCRM(res);
      })
      .catch((err) => {
        let msg = ErrorHandler(err);
        console.log(msg);
      })
      .finally(() => Loading({ type: "COMPLETE" }));
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      setSearchForm({ ...searchForm, page: 1 });
      onSearch();
    }
  };

  const onClickSearch = () => {
    setSearchForm({ ...searchForm, page: 1 });
    onSearch();
  };

  useEffect(() => {
    onSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchForm.page, searchForm.type]);

  const onChangeSearch = (e: any) => {
    if (e.target.name === "parentCategory") {
      setSearchForm({
        ngCategory: "",
        ngCategoryDetail: "",
        resultCategory: "",
        resultCategoryDetail: "",
        category: [],
      });
    } else if (e.target.name === "ngCategory") {
      setSearchForm({
        ngCategoryDetail: "",
      });
    } else if (e.target.name === "resultCategory") {
      setSearchForm({
        resultCategoryDetail: "",
      });
    }
    setSearchForm({ [e.target.name]: e.target.value === null ? "" : e.target.value });
  };

  const onChangeDate = (e: any) => {
    const { name, value } = e.target;
    let formattedDate = "";

    if (value) {
      let parsedDate;

      // Check if the input is a 6-digit string like "YYMMDD"
      if (/^\d{6}$/.test(value)) {
        // Assume the first two digits are the year
        const year = value.substring(0, 2);
        const month = value.substring(2, 4);
        const day = value.substring(4, 6);

        // Convert "22" to "2022"
        const fullYear = year.length === 2 ? `20${year}` : year;

        // Create a moment date from parsed values
        parsedDate = moment(`${fullYear}-${month}-${day}`, "YYYY-MM-DD", true);
      } else {
        // Parse other date formats
        parsedDate = moment(value, ["YYYY-MM-DD", "YY-MM-DD", "YYYYMMDD", "YYMMDD"], true);
      }

      // If valid, format to "YYYY-MM-DD"
      if (parsedDate.isValid()) {
        formattedDate = parsedDate.format("YYYY-MM-DD");
      }
    }

    // Update state
    setSearchForm({ [name]: formattedDate });
  };

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setSearchForm({ ...searchForm, page: page });
  };

  const onChangeType = (e: any) => {
    setSearchForm({ ...searchForm, type: e.target.value, page: 1 });
  };

  // 제품 multi select
  const allChildren = itemCategory.filter((x) => x.id === searchForm.parentCategory)[0]?.children || [];

  const handleChange = (e: any) => {
    const {
      target: { value },
    } = e;

    if (value.includes("all")) {
      const allValues = searchForm.category.length === allChildren.length ? [] : allChildren.map((item) => item.name);
      onChangeSearch({ target: { name: "category", value: allValues } });
    } else {
      onChangeSearch({ target: { name: "category", value } });
    }
  };

  const isAllSelected = searchForm.category.length === allChildren.length;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250,
      },
    },
  };

  // 엑셀 출력
  const areSpecificFieldsEmpty = (obj: SearchCRM) => {
    return (
      (!obj.startDate || obj.startDate === "" || obj.startDate === "Blank") &&
      (!obj.endDate || obj.endDate === "" || obj.endDate === "Blank")
    );
  };

  const onExcel = async () => {
    const today = moment().format("YYYY-MM-DD");
    const oneYearAgo = moment().subtract(1, "year").format("YYYY-MM-DD");

    const searchFormExcel = {
      ...searchForm,
      category: Array.isArray(searchForm.category) ? searchForm.category.join(",") : searchForm.category,
      branch: user.authority === "BranchUser" ? user.branch : searchForm.branch,
    };

    if (areSpecificFieldsEmpty(searchForm)) {
      const userConfirmed = window.confirm(
        "날짜 필드가 비어 있습니다. 최근 1년 데이터만 조회됩니다. 계속하시겠습니까?"
      );

      if (!userConfirmed) {
        return;
      }
      searchFormExcel.dateType = "completedDate";
      searchFormExcel.startDate = oneYearAgo;
      searchFormExcel.endDate = today;
    }

    try {
      Loading({ type: "LOADING_MESSAGE", message: "Downloading..." });
      const response = await fetch("/api/crm/search/excel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserApi.GetToken()}`,
        },
        body: JSON.stringify(searchFormExcel),
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "CS리스트_" + moment().format("YYYYMMDD") + ".xls";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to generate file");
      }
    } catch (err) {
      console.log("Error : ", err);
    } finally {
      Loading({ type: "COMPLETE" });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TitleDiv title="Search"></TitleDiv>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TitleTableCell align="center">항목</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex" }}>
                  <FormDropDown
                    name="classify"
                    value={searchForm.classify}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    fields={{ text: "key", value: "label" }}
                    dataSource={SearchItemType}
                  ></FormDropDown>
                  <FormInput
                    name="text"
                    value={searchForm.text}
                    onChange={onChangeSearch}
                    onKeyDown={handleKeyPress}
                    variant="outlined"
                    size="small"
                    fullWidth
                  ></FormInput>
                </BodyTableCell>
                <BodyTableCell colSpan={10} style={{ border: "none" }}>
                  <Grid style={{ display: "flex", justifyContent: "space-around" }}>
                    <RadioButtonComponent
                      value="전화미처리"
                      label="전화미처리"
                      checked={searchForm.type === "전화미처리"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    <RadioButtonComponent
                      value="방문일정"
                      label="방문일정"
                      checked={searchForm.type === "방문일정"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    {["Admin", "HeadA"].includes(user.authority) && (
                      <RadioButtonComponent
                        value="해피콜"
                        label="해피콜"
                        checked={searchForm.type === "해피콜"}
                        onChange={onChangeType}
                      ></RadioButtonComponent>
                    )}
                    <RadioButtonComponent
                      value="채권"
                      label="채권"
                      checked={searchForm.type === "채권"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                    {/* 본사영업_에 해당하는 branch만 보이도록 */}
                    {["Admin", "HeadA"].includes(user.authority) &&
                      ["HC005342", "HC005343", "HC005345", "HC007964"].includes(user.branch) && (
                        <RadioButtonComponent
                          value="SO생성"
                          label="SO생성"
                          checked={searchForm.type === "SO생성"}
                          onChange={onChangeType}
                        ></RadioButtonComponent>
                      )}
                    <RadioButtonComponent
                      value="전체보기"
                      label="전체보기"
                      checked={searchForm.type === "전체보기"}
                      onChange={onChangeType}
                    ></RadioButtonComponent>
                  </Grid>
                </BodyTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">날짜</TitleTableCell>
                <BodyTableCell align="center" style={{ display: "flex", alignItems: "center" }}>
                  <DatePickerComponent
                    name="startDate"
                    value={new Date(searchForm.startDate)}
                    onChange={onChangeDate}
                    onBlur={onChangeDate}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    format="yy-MM-dd"
                    placeholder="시작일"
                  ></DatePickerComponent>
                  <span> ~ </span>
                  <DatePickerComponent
                    name="endDate"
                    value={new Date(searchForm.endDate)}
                    min={new Date(searchForm.startDate)}
                    onChange={onChangeDate}
                    onBlur={onChangeDate}
                    cssClass="e-outline e-small"
                    width={"120px"}
                    format="yy-MM-dd"
                    placeholder="종료일"
                  ></DatePickerComponent>
                  <FormDropDown
                    name="dateType"
                    value={searchForm.dateType}
                    onChange={onChangeSearch}
                    fields={{ text: "key", value: "label" }}
                    dataSource={searchForm.handlingMethod === "Visit" ? SearchDateType2 : SearchDateType}
                    cssClass="e-outline e-small"
                    width={"120px"}
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리방법</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="handlingMethod"
                    value={searchForm.handlingMethod}
                    fields={{ text: "key", value: "label" }}
                    dataSource={HandlingMethod}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">방문처리결과</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultForVisit"
                    value={searchForm.resultForVisit}
                    fields={{ text: "key", value: "label" }}
                    dataSource={ResultForVisit}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">비용처리</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="paymentType"
                    value={searchForm.paymentType}
                    fields={{ text: "key", value: "label" }}
                    dataSource={PaymentType}
                    onChange={onChangeSearch}
                    cssClass="e-outline e-small"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">작성지사</TitleTableCell>
                <BodyTableCell align="center">
                  {user.authority === "BranchUser" ? (
                    <FormDropDown
                      name="branch"
                      value={user.branch || null}
                      dataSource={branch.filter((x) => x.branchId === user.branch)}
                      fields={{ text: "branchName", value: "branchId" }}
                      cssClass="e-outline e-small"
                      popupWidth="200px"
                    />
                  ) : (
                    <FormDropDown
                      name="branch"
                      value={searchForm.branch}
                      dataSource={branch}
                      fields={{ text: "branchName", value: "branchId" }}
                      onChange={onChangeSearch}
                      cssClass="e-outline e-small"
                      popupWidth="200px"
                    ></FormDropDown>
                  )}
                </BodyTableCell>
                {["Admin", "HeadA"].includes(user.authority) && (
                  <>
                    <TitleTableCell align="center"> IHQ 발행</TitleTableCell>
                    <BodyTableCell align="center">
                      <FormDropDown
                        name="erp"
                        value={searchForm.erp}
                        onChange={onChangeSearch}
                        fields={{ text: "key", value: "label" }}
                        dataSource={SearchStatusType}
                        cssClass="e-outline e-small"
                      ></FormDropDown>
                    </BodyTableCell>
                  </>
                )}
              </TableRow>
              <TableRow>
                <TitleTableCell align="center">제품선택</TitleTableCell>
                <BodyTableCell align="center">
                  <Grid item display={"flex"}>
                    <FormDropDown
                      name="parentCategory"
                      value={searchForm.parentCategory || null}
                      fields={{ text: "id", value: "id" }}
                      dataSource={itemCategory}
                      showClearButton={true}
                      onChange={onChangeSearch}
                      cssClass="e-outline e-small"
                      width="120px"
                    ></FormDropDown>
                    <Select
                      multiple
                      name="category"
                      value={searchForm.category || []}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      size="small"
                      renderValue={(selected) => selected.join(", ")}
                      sx={{
                        width: "250px",
                        height: "36px",
                        fontSize: "13px",
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#e3165b", // 포커스 시 외곽선 색상
                        },
                      }}
                      MenuProps={MenuProps}
                    >
                      <FormSelectMenuItem className="custom-menu-item" dense={true} value="all">
                        <FormSelectCheckBox checked={isAllSelected} />
                        <ListItemText primary="Select All" />
                      </FormSelectMenuItem>
                      {allChildren.map((item) => (
                        <FormSelectMenuItem className="custom-menu-item" dense={true} key={item.name} value={item.name}>
                          <FormSelectCheckBox checked={searchForm.category.indexOf(item.name) > -1} />
                          <ListItemText primary={item.name} />
                        </FormSelectMenuItem>
                      ))}
                    </Select>
                  </Grid>
                </BodyTableCell>
                <TitleTableCell align="center">불량구분대분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategory"
                    value={searchForm.ngCategory || null}
                    fields={{ text: "name", value: "name" }}
                    dataSource={ngCategory.filter((x) => searchForm.parentCategory === x.name)[0]?.children || []}
                    onChange={onChangeSearch}
                    showClearButton={true}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">불량구분소분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="ngCategoryDetail"
                    value={searchForm.ngCategoryDetail}
                    fields={{ text: "name", value: "name" }}
                    dataSource={
                      ngCategory
                        .filter((x) => searchForm.parentCategory === x.name)[0]
                        ?.children.filter((x) => x.name === searchForm.ngCategory)[0]?.children
                    }
                    onChange={onChangeSearch}
                    showClearButton={true}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리결과대분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategory"
                    value={searchForm.resultCategory}
                    fields={{ text: "name", value: "name" }}
                    dataSource={resultCategory.filter((x) => searchForm.parentCategory === x.name)[0]?.children || []}
                    onChange={onChangeSearch}
                    showClearButton={true}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                <TitleTableCell align="center">처리결과소분류</TitleTableCell>
                <BodyTableCell align="center">
                  <FormDropDown
                    name="resultCategoryDetail"
                    value={searchForm.resultCategoryDetail}
                    fields={{ text: "name", value: "name" }}
                    dataSource={
                      resultCategory
                        .filter((x) => searchForm.parentCategory === x.name)[0]
                        ?.children.filter((x) => x.name === searchForm.resultCategory)[0]?.children
                    }
                    onChange={onChangeSearch}
                    showClearButton={true}
                    cssClass="e-outline e-small"
                    popupWidth="200px"
                  ></FormDropDown>
                </BodyTableCell>
                {searchForm.type === "SO생성" && (
                  <>
                    <TitleTableCell align="center">SO 생성</TitleTableCell>
                    <BodyTableCell align="center">
                      <FormDropDown
                        name="so"
                        value={searchForm.so}
                        onChange={onChangeSearch}
                        fields={{ text: "key", value: "label" }}
                        dataSource={SearchStatusType}
                        cssClass="e-outline e-small"
                      ></FormDropDown>
                    </BodyTableCell>
                  </>
                )}
                <BodyTableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  총 {crm.count}건
                </BodyTableCell>
                <BodyTableCell align="center" style={{ padding: "0px" }}>
                  <FormButton onClick={onExcel}>엑셀</FormButton>
                  <FormButton onClick={onClickSearch}>조회</FormButton>
                </BodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <SearchTable type={searchForm.type} data={crm.list}></SearchTable>
        <Pagination
          count={crm.totalPage}
          page={searchForm.page}
          onChange={onPageChange}
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
}

export default SearchMain;
